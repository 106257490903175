import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import useGetData from "../../CustomHooks/useGetData";
import { db } from "../../firebase/config";

const SuccessPayment = () => {
  const navigate = useNavigate();
  const userID = useSelector((state) => state.orebiReducer.userID);
  console.log("userID from Redux:", userID);

  const { data: orders, loading } = useGetData("ordered", false, true);
  console.log("Orders fetched:", orders);
  console.log("Loading status:", loading);

  useEffect(() => {
    console.log(
      "useEffect triggered with orders:",
      orders,
      "and userID:",
      userID
    );

    const updateCoupons = async () => {
      if (orders && userID) {
        const userOrders = orders.find((order) => order.id === userID);
        console.log("User orders:", userOrders);

        if (userOrders && userOrders.orders) {
          // Filter orders with status "Paid" and with a valid appliedCouponId
          const paidOrdersWithCoupons = userOrders.orders.filter(
            (order) => order.status === "Paid" && order.appliedCouponId
          );

          for (const paidOrder of paidOrdersWithCoupons) {
            try {
              console.log(
                "Updating coupon with ID:",
                paidOrder.appliedCouponId
              );
              const couponRef = doc(db, "coupons", paidOrder.appliedCouponId);
              await updateDoc(couponRef, { status: "Used" });
              console.log(
                `Coupon ${paidOrder.appliedCouponId} updated successfully.`
              );
              toast.success(
                `Coupon ${paidOrder.appliedCouponId} marked as used!`
              );
            } catch (error) {
              console.error(
                `Error updating coupon ${paidOrder.appliedCouponId}:`,
                error
              );
              toast.error(
                `Failed to update coupon ${paidOrder.appliedCouponId}.`
              );
            }
          }
        }
      }
    };

    updateCoupons();
  }, [orders, userID]);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  return (
    <div className="payment-success-container flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="max-w-lg bg-white shadow-md rounded-lg p-8 text-center">
        <h1 className="text-3xl font-bold text-green-600">
          Payment Successful!
        </h1>
        <p className="mt-4 text-lg text-gray-700">
          Thank you for your payment. Your transaction was completed
          successfully.
        </p>
        <button
          onClick={() => navigate("/order-history")}
          className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
        >
          View Order History
        </button>
      </div>
    </div>
  );
};

export default SuccessPayment;
