import React, { createContext, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn, selectUserID } from "./IchthusSlice";
import useGetData from "../CustomHooks/useGetData";

const INITIAL_STATE = {
  currentUser: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userID = useSelector(selectUserID);
  const dispatch = useDispatch();
  const { data: UsersData, loading } = useGetData("user", false, true);

  // Find the current user from UsersData based on userID
  const currentUser = useMemo(() => {
    return (
      !loading &&
      UsersData.length > 0 &&
      UsersData.find((user) => user.id === userID)
    );
  }, [userID, UsersData, loading]);

  useEffect(() => {
    // Optionally log the current user for debugging
    console.log("Current User:", currentUser);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
