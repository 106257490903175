import React, { useState, useEffect } from "react";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return !isOnline ? (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-red-600 to-red-400 text-white text-center py-3 shadow-lg">
      <div className="flex items-center justify-center gap-2">
        <span className="material-icons">wifi_off</span>
        <p className="text-sm md:text-base">
          You are offline. Please check your connection.
        </p>
      </div>
    </div>
  ) : null;
};

export default NetworkStatus;
